<template>
  <div class="tax_vat">
    <card>
      <div slot="header">
        <h4 class="card-title">Impuestos IVA</h4>
        <p class="card-category">Valores para los diferentes impuestos.</p>
      </div>

      <!-- editar impuesto -->
      <form v-if="editRow" class="ignore-discounts-form">
        <h4>Editar Impuesto</h4>
        <div class="form-fields">
          <div class="form-field">
            <div class="d-inline-block py-2">
              <label>Forma de Pago:</label>
              <span>{{ editRow?.paymentMethodId | formaPago }}</span>
            </div>
          </div>
          <div class="form-field">
            <div class="d-inline-block py-2">
              <label>Opción de Tasa:</label>
              <span>{{ editRow?.rateOptionId | opcionTasa }}</span>
            </div>
          </div>
          <div class="form-field">
            <div class="d-inline-block py-2">
              <label>Aseguradora:</label>
              <span>{{ editRow?.companyId | compañias }}</span>
            </div>
          </div>
          <div class="form-field">
            <div class="d-inline-block py-2">
              <label>Unidad:</label>
              <span>{{ editRow?.hectarePriceCurrencyId | unidadValorHA }}</span>
            </div>
          </div>
          <div class="form-field">
            <div class="d-inline-block py-2">
              <label>Porcentaje:</label>
              <fg-input type="text" v-model="ivaValue"></fg-input>
            </div>
          </div>
        </div>
        <div class="button-container">
          <button class="btn btn-primary my-1" @click="saveEditedTax()">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            Guardar cambios
          </button>
          <button class="btn btn-secondary my-1" @click="cleanEditRow">
            <i class="fa fa-floppy-o" aria-hidden="true"></i>
            Cancelar cambios
          </button>
        </div>
      </form>

      <!-- impuestos cargados -->
      <div slot="raw-content" class="table-responsive" v-if="isDataLoaded">
        <entity-table
          :columns="['Tipo de Pago', 'Opción de Tasa', 'Aseguradora', 'Unidad', 'Porcentaje']"
          type="hover"
          class="table-sm"
          :tableData="taxes"
          :total="total"
          @refetch="fetchTaxes"
        >
          <template slot="filters">
            <div class="tw-inline-block">
              <fg-input
                cy-id="search"
                type="text"
                label="Buscar cotización"
                placeholder="Buscar"
                class="search-input mr-3"
                v-model="params.search"
              />
            </div>
            <div class="tw-inline-block tw-w-[10rem]">
              <label class="mb-1">Aseguradora</label>
              <VueSelect v-model="params.selectedCompany" :options="companyOptions" class="min-w-[10rem]">
                <span slot="no-options">No se encontraron aseguradoras.</span>
              </VueSelect>
            </div>
          </template>
          <template slot-scope="{ row }">
            <td>{{ row.paymentMethodId | formaPago }}</td>
            <td>{{ row.rateOptionId | opcionTasa }}</td>
            <td>{{ row.companyId | compañias }}</td>
            <td>{{ row.hectarePriceCurrencyId | unidadValorHA }}</td>
            <td>{{ row.value }}</td>
            <td>
              <button class="btn btn-primary btn-xs" @click="showEditTax(row)">
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </template>
        </entity-table>
      </div>
    </card>
  </div>
</template>

<script>
import EntityTable from '@/components/EntityTable';
import VueSelect from '@/components/VueSelect.vue';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TaxVats',
  components: {
    EntityTable,
    VueSelect
  },
  data() {
    return {
      taxes: [],
      editRow: null,
      isDataLoaded: false,
      total: 0,
      ivaValue: 0,
      params: {
        search: '',
        selectedCompany: null
      }
    };
  },

  computed: {
    ...mapState('companies', {
      companies: state => state.companies
    }),
    companyOptions() {
      return this.companies.map(company => ({
        label: company.name,
        value: company.id
      }));
    }
  },

  mounted() {
    this.fetchTaxes();
    this.fetchCompanies();
  },

  watch: {
    'params.search': {
      handler(newValue) {
        setTimeout(() => {
          if (newValue === this.params.search) {
            this.fetchTaxes();
          }
        }, 1200);
      },
      deep: true
    },
    'params.selectedCompany': {
      handler(newValue) {
        setTimeout(() => {
          if (newValue === this.params.selectedCompany) {
            this.fetchTaxes();
          }
        }, 1200);
      },
      deep: true
    }
  },

  methods: {
    ...mapActions('companies', ['fetchCompanies']),

    fetchTaxes(params = { 'filter[search]': this.params.search, perPage: 10, currentPage: 1 }) {
      if (this.params.selectedCompany) {
        params['filter[companyId]'] = this.params.selectedCompany.value;
      }
      this.$api.taxVats.getAll(params).then(response => {
        this.taxes = response.data.taxVats;
        this.total = response.meta.total;
        this.isDataLoaded = true;
      });
    },
    saveEditedTax() {
      this.editRow.value = this.ivaValue;
      this.$api.taxVats.update(this.editRow.id, this.editRow).then(response => {
        this.$swal({
          title: 'Éxito!',
          text: 'Impuesto editado correctamente',
          type: 'success'
        });
      });
      this.ivaValue = null;
    },
    showEditTax(tax) {
      this.editRow = tax;
      this.ivaValue = tax.value;
    },
    cleanEditRow() {
      this.ivaValue = null;
      this.editRow = null;
    }
  }
};
</script>

<style scoped>
.button-container .btn {
  margin: 10px;
}

@media (max-width: 600px) {
  .button-container {
    display: flex;
    flex-direction: column;
  }

  .button-container .btn {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
