<template>
  <div class="row">
    <div class="col-12">
      <card>
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ coveragesTable.titulo }}</h4>
            <p class="card-category">{{ coveragesTable.subtitulo }}</p>
          </div>
          <div class="card-header-button-container">
            <router-link cy-id="add-coverage" to="/admin/coberturas/nuevo" class="btn btn-primary">
              Agregar nueva
            </router-link>
            <router-link
              v-if="$auth.check('ROLE_ADMIN') || $auth.check('ROLE_SUDO')"
              to="/admin/coberturas/importar"
              class="btn btn-info"
            >
              Importar Tasas
            </router-link>
            <router-link
              v-if="$auth.check('ROLE_ADMIN') || $auth.check('ROLE_SUDO')"
              to="/admin/coberturas/exportar"
              class="btn btn-info"
            >
              Exportar Tasas
            </router-link>
          </div>
        </template>

        <div></div>

        <div slot="raw-content" class="table-responsive">
          <EntityTable
            :columns="coveragesTable.columns"
            :tableData="coveragesTable.data"
            type="hover"
            :routeName="coveragesTable.routeName"
            :total="coveragesTable.total"
            @refetch="getModules"
          >
            <template slot="filters">
              <div class="tw-inline-block">
                <fg-input
                  cy-id="search"
                  type="text"
                  label="Buscar cotización"
                  placeholder="Buscar"
                  class="search-input mr-3"
                  v-model="params.search"
                />
              </div>
              <div class="tw-inline-block tw-w-[10rem]">
                <label class="mb-1">Aseguradora</label>
                <VueSelect v-model="params.selectedCompany" :options="companyOptions">
                  <span slot="no-options">No se encontraron aseguradoras.</span>
                </VueSelect>
              </div>
            </template>
            <template slot-scope="{ row }">
              <td>{{ row.description }}</td>
              <td>{{ row.company.name }}</td>
              <td>{{ row.pdfDescription ? 'Si' : 'No' }}</td>
              <td>{{ row.cropDescription }}</td>
              <td>{{ row.harvest | tipoCosecha }}</td>
              <td>
                <span class="text-uppercase badge" :class="coverageTypeBadgeClass(row.coverageType)">
                  {{ coverageTypeName(row.coverageType) }}
                </span>
              </td>
              <td>
                <router-link :to="{ name: 'ver cobertura', params: { id: row.id } }" class="btn btn-xs btn-info">
                  <i class="ti-eye"></i>
                  Ver
                </router-link>
              </td>
            </template>
          </EntityTable>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import EntityTable from '@/components/EntityTable';
import VueSelect from '@/components/VueSelect.vue';

const tableColumns = ['Cobertura', 'Companía', 'Incluye descriptivo', 'Cultivo', 'Cosecha', 'Tipo'];

export default {
  components: {
    EntityTable,
    VueSelect
  },
  data() {
    return {
      coveragesTable: {
        title: 'Coberturas',
        subtitle: 'Todos las Coberturas registradas.',
        columns: [],
        data: [],
        total: 0,
        routeName: 'ver cobertura'
      },
      params: {
        search: '',
        selectedCompany: null
      }
    };
  },

  computed: {
    ...mapState('companies', {
      companies: state => state.companies
    }),
    companyOptions() {
      return this.companies.map(company => ({
        label: company.name,
        value: company.id
      }));
    }
  },

  created() {
    this.getModules();
    this.fetchCompanies();
    this.coveragesTable.columns = tableColumns;
  },
  watch: {
    'params.search': {
      handler(newValue) {
        setTimeout(() => {
          if (newValue === this.params.search) {
            this.getModules();
          }
        }, 1200);
      },
      deep: true
    },
    'params.selectedCompany': {
      handler(newValue) {
        setTimeout(() => {
          if (newValue === this.params.selectedCompany) {
            this.getModules();
          }
        }, 1200);
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('companies', ['fetchCompanies']),
    getModules(
      params = {
        'filter[search]': this.params.search,
        perPage: 10,
        page: 1
      }
    ) {
      if (this.params.selectedCompany) {
        params['filter[company]'] = this.params.selectedCompany.value;
      }
      this.$api.modules.getAll(params).then(response => {
        this.coveragesTable.data = response.data.modules;
        this.coveragesTable.total = response.meta.total;
      });
    },
    coverageTypeName(type) {
      switch (type) {
        case 'basic':
          return 'Básica';
          break;
        case 'addon':
          return 'Adicional';
          break;
        case 'pack':
          return 'Pack';
          break;
        case 'double-crop':
          return 'Doble Cultivo';
          break;
        default:
          return '';
      }
    },
    coverageTypeBadgeClass(type) {
      switch (type) {
        case 'basic':
          return 'badge-primary';
          break;
        case 'addon':
          return 'badge-info';
          break;
        case 'pack':
          return 'badge-success';
          break;
        case 'double-crop':
          return 'badge-warning';
          break;
        default:
          return '';
      }
    }
  }
};
</script>
<style></style>
