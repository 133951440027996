export default {
  //negotiations state
  ACCEPTED: 'aceptada',
  REJECTED: 'rechazada',
  NEW: 'iniciada',
  UPDATED: 'recotizada',
  SOLICITUDE: 'solicitud',
  // harvest video type
  THICK_HARVEST: { name: 'Gruesa', key: 'thick' },
  THIN_HARVEST: { name: 'Fina', key: 'thin' },
  // currencies types
  QUINTAL: 1,
  DOLLAR: 2,
  PESO: 3,
  // negotiation types
  SIMPLE: 'simple',
  GROUPED: 'grouped',
  INITIATED: 'iniciada',
  // confirmation negotiation
  CONFIRMED: 1,
  NOT_CONFIRMED: 0,

  SANCOR: 1,
  SANCRISTOBAL: 2
};
