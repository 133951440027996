<template>
  <div>
    <card class="accepted-card" :class="cardClass">
      <p class="accepted-heading">{{ acceptedHeading }}</p>

      <div class="d-block justify-content-around d-sm-flex proposal-data" v-if="hasProposal">
        <div class="flex-grow-1 accepted-item">
          <p class="h6">Nro. de tramite</p>
          <p class="accepted-item-value">
            {{ proposal.managementNumber }}
          </p>
        </div>
        <div class="flex-grow-1 accepted-item">
          <p class="h6">Nro. de recibo</p>
          <p class="accepted-item-value">
            {{ proposal.receiptNumber }}
          </p>
        </div>
        <div class="flex-grow-1 accepted-item">
          <p class="h6">Nro. de referencia</p>
          <p class="accepted-item-value">
            {{ proposal.referenceNumber }}
          </p>
        </div>
      </div>

      <div class="d-block justify-content-around d-sm-flex accepted-quotation">
        <div class="flex-grow-1 accepted-item">
          <div>
            <p class="h6">Cobertura</p>
            <p class="accepted-item-coverage">
              {{ quotation.rate.module.description }}
            </p>
          </div>
          <div v-if="hasAddons">
            <p class="h6">Adicionales</p>
            <p class="accepted-item-addons">{{ addonsToString }}</p>
          </div>
          <div>
            <p class="h6">Opción de cobertura</p>
            <p class="accepted-item-addons">
              {{ quotation.rate.franchiseDeductible }}
            </p>
          </div>
        </div>
        <div class="flex-grow-1 flex-shrink-0 accepted-item">
          <p class="h6">Tasa</p>
          <p class="accepted-item-value">
            {{ quotation.rateValueWithCf | rateFormat }}
          </p>
        </div>
        <div class="flex-grow-1 flex-shrink-0 accepted-item">
          <p class="h6">Costo</p>
          <p class="accepted-item-value">{{ quotation.value | formatoValor }} {{ valueUnit | unidadValorHA }}</p>
        </div>
        <div class="flex-grow-1 flex-shrink-0 accepted-item">
          <p class="h6">Costo por HA</p>
          <p class="accepted-item-value">
            {{ quotation.costPerHectare | formatoValor }}
            {{ valueUnit | unidadValorHA }}
          </p>
        </div>
      </div>
    </card>

    <div class="row">
      <div class="col-sm mb-3">
        <!-- buttons -->
        <button class="flex-grow-1 btn btn-info btn-block" @click="$emit('getPdf')">
          <i class="fa fa-cloud-download" aria-hidden="true"></i>
          PDF Solicitud de cobertura
        </button>
      </div>
      <div class="col-sm mb-3">
        <button class="flex-grow-1 btn btn-primary btn-block" @click="$emit('getAdvancePdf')">
          <i class="fa fa-cloud-download" aria-hidden="true"></i>
          PDF Anticipo
        </button>
      </div>
      <div class="col-sm mb-3" v-if="canMakeProposal">
        <router-link
          :to="{ name: 'nueva propuesta', params: { id: this.quotation.id } }"
          class="flex-grow-1 btn btn-success btn-block"
        >
          <i class="fa fa-share" aria-hidden="true"></i>
          Generar propuesta
        </router-link>
      </div>
      <div class="col-sm mb-3" v-if="hasProposal">
        <button class="flex-grow-1 btn btn-warning btn-block" @click.stop="" disabled>
          <i class="fa fa-book" aria-hidden="true"></i>
          Registrar Poliza
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { downloadBlob } from '@/plugins/files.js';
export default {
  props: {
    quotation: {
      type: Object,
      default: () => {}
    },
    valueUnit: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      proposal: {}
    };
  },
  mounted() {
    if (this.hasProposal) this.fetchProposalData();
  },
  computed: {
    cardClass() {
      return {
        advanced: this.isAdvanced,
        proposal: this.hasProposal
      };
    },
    isAdvanced() {
      return this.$props.quotation.quotationStatuses.some(qs => qs.description === 'advanced');
    },
    hasProposal() {
      return this.$props.quotation.quotationStatuses.some(qs => qs.description === 'proposal');
    },
    hasAddons() {
      return this.$props.quotation.addons.length > 0;
    },
    addonsToString() {
      let addons = this.$props.quotation.addons.map(ad => ad.module.description);
      return addons.join(', ');
    },
    acceptedHeading() {
      let heading = 'Cotizacion aceptada';
      if (this.isAdvanced) {
        heading = 'Cotizacion anticipada';
      }
      if (this.hasProposal) {
        // const managementNumber = this.$props.quotation.proposal.management_number
        heading = 'Propuesta';
      }
      return heading;
    },
    isSancorCoverage() {
      return this.$props.quotation.rate.module.sancorId;
    },
    canMakeProposal() {
      return (
        !this.hasProposal &&
        (this.$auth.check('ROLE_ADMIN') || this.$auth.check('ROLE_SUDO') || this.$auth.user().send_proposal) &&
        this.isSancorCoverage
      );
    }
  },

  methods: {
    fetchProposalData() {
      this.$api.proposal.getByQuotationId(this.quotation.id).then(response => {
        this.proposal = response.data;
      });
    },
    getAcceptedPdf() {
      this.$api.quotations.acceptedPdf(this.quotation.id).then(response => {
        const fileName = `cotizacion_aceptada_${this.quotation.id}.pdf`;
        downloadBlob(new Blob([response]), fileName);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.accepted-card {
  // background-color: #41b883;
  background-color: #68b3c8;
  color: #fff !important;

  &.advanced {
    background-color: #7a9e9f;
  }

  &.proposal {
    // background-color: #F3BB45;
    background-color: #41b883;
  }

  .accepted-heading {
    font-weight: bold;
    font-size: 1.825em;
    margin: 20px 0;
    opacity: 0.6;
  }

  .proposal-data {
    border-bottom: 1px dashed #fff;
    margin-bottom: 20px;
  }

  .accepted-item {
    padding: 0 20px;
    margin-bottom: 15px;
    display: inline-block;

    .accepted-item-coverage,
    .accepted-item-value {
      font-weight: bold;
      font-size: 16px;
      margin: 0;
    }

    .accepted-item-coverage {
      margin-bottom: 15px;
    }

    .accepted-item-addons {
      font-weight: bold;
      font-size: 16px;
      opacity: 0.7;
      margin: 0;
    }
  }
}

.btn i.fa {
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
