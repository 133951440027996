<template>
  <card
    title="Import masivo"
    sub-title="Seleccione un archivo de su computadora y clickee el boton para iniciar la importacion de primas."
  >
    <form enctype="multipart/form-data" @submit.prevent="initImport" novalidate v-if="!isSaving">
      <div class="row">
        <div class="col-sm-3">
          <div class="form-group">
            <label for="file">Archivo (XLSX)</label>
            <input type="file" id="file" ref="file" accept=".xlsx" @change="processFile($event)" />
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>Campaña</label>
            <select class="custom-select" v-model="campaign">
              <option value="">Campaña seleccionada</option>
              <option v-for="(campaign, index) in campaigns" :key="index" :value="campaign.id">
                {{ campaign.description }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-3">
          <div class="form-group">
            <label>Aseguradora</label>
            <select class="custom-select" v-model="company">
              <option value="">Aseguradora seleccionada</option>
              <option v-for="(company, index) in userCompanies" :key="index" :value="company.id">
                {{ company.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <button class="btn btn-success" :disabled="disabled">Iniciar importación</button>
    </form>
    <span v-if="isSaving">CARGANDO</span>
    <div class="results" v-if="haveResults">
      <h4>Resultado del import</h4>
      <table class="table">
        <thead>
          <th>Fila</th>
          <th>Motivo</th>
        </thead>
        <tbody>
          <tr v-for="(sr, index) in skippedRows" :key="index">
            <td>{{ sr.row_number }}</td>
            <td>{{ sr.type }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      disabled: true,
      campaigns: {},
      campaign: '',
      company: '',
      harvest: 'thin',
      file: '',
      isSaving: false,
      skippedRows: []
    };
  },
  created() {
    this.fetchCampaignData();
    this.fetchUserCompanies();
  },
  computed: {
    ...mapState('companies', {
      userCompanies: state => state.userCompanies
    }),
    haveResults() {
      return this.skippedRows.length > 0 && this.isSaving == false;
    }
  },
  methods: {
    ...mapActions('companies', ['fetchUserCompanies']),

    fetchCampaignData() {
      this.$api.campaigns.getAllCampaings().then(response => {
        this.campaigns = response.data.campaigns;
      });
    },

    initImport() {
      if (!this.file.name.includes('.xlsx')) {
        this.$swal({
          title: 'Tipo de archivo inválido',
          text: 'Seleccione un archivo XLSX',
          type: 'error'
        });
      } else {
        this.skippedRows = [];
        this.isSaving = true;
        let formData = new FormData();
        formData.append('campaign', this.campaign);
        formData.append('import', this.file);
        formData.append('company', this.company);

        this.$api.rates.import(formData).then(response => {
          this.skippedRows = response.import_report.errors;
          this.isSaving = false;
          this.$swal({
            title: 'Listo!',
            text: 'La importacion de tasas fue realizada con éxito.',
            type: 'success',
            footer: `Nuevas: ${response.import_report.rates_imported}
               / No procesadas: ${response.import_report.cant_errors}`
          });
        });
      }
    },
    processFile(ev) {
      this.file = ev.target.files[0];
      this.disabled = false;
    }
  }
};
</script>

<style lang="scss" scoped>
$custom-file-text: (
  en: 'Browse',
  es: 'Seleccionar archivo'
);

.results {
  margin-top: 40px;
}
</style>
