<template>
  <div class="row">
    <div class="col-lg-12">
      <card title="Cobertura Basica Resiembra 75%">
        <template slot="header">
          <div class="pull-left">
            <h4 class="card-title">{{ module.description }}</h4>
            <p class="card-category">
              Acá tenes todas las primas de la cobertura. Podes filtrarlas seleccionando las opciones que están arriba
              de la tabla.
            </p>
          </div>
          <div class="card-header-button-container">
            <button cy-id="add-rate" class="btn btn-info" @click="showForm = true">Nueva Tasa</button>
            <router-link cy-id="edit-rate" :to="{ name: editarUrl, params: { id: moduleId } }" class="btn btn-warning">
              Editar
            </router-link>
            <button cy-id="delete-rate" class="btn btn-danger" @click="deleteModule">Eliminar</button>
          </div>
        </template>
        <!-- agregar nueva tasa -->
        <rate-form
          v-if="showForm"
          :provinces="provinces"
          :zones="zones"
          :crops="crops"
          @close="showForm = false"
          @submit="newRate"
          ref="RateForm"
        />
        <div slot="raw-content" class="table-responsive">
          <rate-table
            :mostrarCobertura="false"
            :columns="moduleRatesTable.columns"
            :tableData="moduleRatesTable.data"
            :total="moduleRatesTable.total"
            :provinces="provinces"
            :zones="zones"
            type="hover"
            @refetch="getModuleRatesPag"
          >
            <template slot-scope="{ row }">
              <template v-if="!showEditForm(row)">
                <td>{{ row.crop.description }}</td>
                <td>{{ row.zone.description }} ({{ row.zone.province.name }})</td>
                <td>{{ row.franchiseDeductible }}</td>
                <td>{{ row.amount.toFixed(2) }}%</td>
                <td>
                  <button class="btn btn-xs btn-warning mx-1" @click="editRate(row)">Editar</button>
                  <button class="btn btn-xs btn-danger" @click="deleteRate(row)">Eliminar</button>
                </td>
              </template>
              <td colspan="5" v-else>
                <rate-form
                  @close="edittedRate = null"
                  @submit="updateRate"
                  :editForm="true"
                  :rate="row"
                  :crops="crops"
                  :provinces="provinces"
                  :zones="zones"
                  ref="RateForm"
                />
              </td>
            </template>
          </rate-table>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import RateForm from '@/components/Modules/Form/RateForm';
import RateTable from '@/components/Modules/Others/RateTable';
import { mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    RateForm,
    RateTable
  },
  data() {
    return {
      moduleRatesTable: {
        title: 'Tasas del modulo',
        subtitle: 'Todas las tasas del modulo. Para agregar uno nuevo, presiona el boton que esta a la derecha.',
        columns: ['Cultivo', 'Zona', 'Deducible/Franquicia', 'Premio sin IVA'],
        data: [],
        total: 0,
        routeName: 'ver tasa'
      },
      module: {},
      tasasPrima: {},
      editarUrl: 'editar cobertura',
      coberturaSeleccionada: {},
      showForm: false,
      edittedRate: null,
      zones: [],
      crops: [],
      provinces: [],
      modules: []
    };
  },

  created() {
    this.getModule();
    this.getModuleRatesPag();
    this.getCrops();
    this.getProvinces();
  },
  computed: {
    moduleId() {
      return this.$route.params.id;
    },
    showEditForm() {
      return rate => this.edittedRate && rate.id === this.edittedRate.id;
    }
  },
  methods: {
    getModule() {
      this.$api.modules.getOne(this.moduleId).then(response => {
        this.module = response.data;
        this.getZones();
      });
    },
    getZones() {
      const params = { 'filter[company]': this.module.companyId };
      this.$api.zones.getAll(params).then(({ data }) => {
        this.zones = data.zones;
      });
    },
    getCrops() {
      this.$api.crops.getAll().then(({ data }) => {
        this.crops = data.crops;
      });
    },
    getProvinces() {
      this.$api.provinces.getAll().then(res => {
        this.provinces = res.data.provinces;
      });
    },
    getModuleRatesPag(params = { 'filter[search]': '', perPage: 10, page: 1 }) {
      this.$api.modules.getModuleRatesPag(this.moduleId, params).then(res => {
        this.moduleRatesTable.data = res.data.rates;
        this.moduleRatesTable.total = res.meta.total;
      });
    },
    newRate(rate) {
      rate.description = ' ';
      rate.moduleId = this.moduleId;
      this.$api.rates.create(rate).then(() => {
        this.$swal({
          title: 'Listo!',
          text: 'Tasa creada correctamente.',
          type: 'success'
        });
        this.$refs.RateForm.resetForm();
        this.showForm = false;
        this.getModuleRatesPag();
      });
    },
    editRate(rate) {
      this.edittedRate = rate;
    },
    updateRate(rate) {
      rate.amount = parseFloat(rate.amount);
      if (rate.id) {
        this.$api.rates.update(rate.id, rate).then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'Tasa creada correctamente.',
            type: 'success'
          });
          this.getModuleRatesPag();
          this.$refs.RateForm.resetForm();
          this.edittedRate = null;
        });
      }
    },
    deleteRate(rate) {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, eliminar!'
      }).then(result => {
        if (result.value) {
          this.$api.rates.destroy(rate.id).then(response => {
            this.$swal({
              title: 'Listo!',
              text: 'La tasa fue eliminada correctamente.',
              type: 'success'
            });
            this.getModuleRatesPag();
          });
        }
      });
    },
    deleteModule() {
      this.$swal({
        title: '¿Estás seguro?',
        text: 'Luego de esto, no podrás deshacer los cambios.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonText: 'No',
        confirmButtonText: 'Si, eliminar!'
      }).then(result => {
        if (result.value) {
          this.$api.modules.destroy(this.moduleId).then(response => {
            this.$swal({
              title: 'Listo!',
              text: 'La cobertura fue eliminada correctamente.',
              type: 'success'
            });
            this.goBack();
          });
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
