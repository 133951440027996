import constants from './constants.js';
import paymentMethods from './paymentMethods.js';

const GlobalFilters = {
  install(Vue) {
    Vue.filter('uppercase', value => {
      return value.toUpperCase();
    });

    Vue.filter('formatoValor', value => {
      // Valor con separador de miles, con separador de decimales, dos decimales y unidad de valor
      return Number.parseFloat(value).toFixed(2);
    });

    Vue.filter('rateFormat', value => {
      return Number.parseFloat(value).toFixed(2) + '%';
    });

    Vue.filter('formatoFecha', value => {
      let date = new Date(value);
      return date.toLocaleString('es-AR');
    });

    Vue.filter('acortar', value => {
      if (value?.length > 40) {
        return value.substring(0, 40) + '...';
      }
      return value;
    });

    Vue.filter('unidadValorHA', value => {
      switch (value) {
        case constants.QUINTAL:
          return 'QQ';
        case constants.DOLLAR:
          return 'USD';
        case constants.PESO:
          return '$';
        default:
          return '';
      }
    });

    Vue.filter('currencySymbol', value => {
      switch (value) {
        case 'quintal':
          return 'QQ';
        case 'dollar':
          return 'USD';
        case 'pesos':
          return '$';
        default:
          return '';
      }
    });

    Vue.filter('formaPago', value => {
      switch (value) {
        case paymentMethods.EXCHANGE:
          return 'Canje';
        case paymentMethods.FINANCED:
          return 'Financiado';
        case paymentMethods.KIND:
          return 'Pago en especie';
        case paymentMethods.FINANCED_KIND:
          return 'Pago en especie financiado';
        case paymentMethods.WITH_ADJUSTMENT:
          return 'Quintales Con Ajuste';
        case null:
          return 'Canje abierto';
        default:
          return '-';
      }
    });

    Vue.filter('opcionTasa', value => {
      switch (value) {
        case paymentMethods.WITHVAT:
          return 'Premio con IVA';
        case paymentMethods.WITHOUTVAT:
          return 'Premio sin IVA';
        case paymentMethods.BEFORETAXES:
          return 'Premio antes de impuestos';
        default:
          return '-';
      }
    });

    Vue.filter('compañias', value => {
      switch (value) {
        case constants.SANCOR:
          return 'Sancor';
        case constants.SANCRISTOBAL:
          return 'San Cristobal';
        default:
          return '-';
      }
    });

    Vue.filter('iva', value => {
      switch (value) {
        case paymentMethods.WITHVAT:
          return 'con IVA';
        case paymentMethods.WITHOUTVAT:
          return 'sin IVA';
        default:
          return '';
      }
    });

    Vue.filter('tipoCosecha', value => {
      switch (value) {
        case 'thin':
          return 'Fina';
          break;
        case 'thick':
          return 'Gruesa';
          break;
        default:
          return '';
      }
    });

    Vue.filter('tipoCotizacion', value => {
      switch (value) {
        case 'simple':
          return 'Simple';
          break;
        case 'grouped':
          return 'Multiple';
          break;
        case 'double-crop':
          return 'Doble cultivo';
          break;
        default:
          return '';
      }
    });

    Vue.filter('formatStatus', value => {
      switch (value) {
        case 'new':
          return 'Nueva';
          break;
        case 'accepted':
          return 'Aceptada';
          break;
        case 'updated':
          return 'Recotizada';
          break;
        case 'rejected':
          return 'Rechazada';
          break;
        case 'advanced':
          return 'Anticipada';
          break;
        case 'proposal':
          return 'Propuesta';
          break;
        case 'policy':
          return 'Póliza';
          break;
        default:
          return '';
      }
    });
  }
};

export default GlobalFilters;
