import { axios } from '@/auth';
import services from '@/api/services';

const companies = {
  namespaced: true,
  state: {
    companies: [],
    userCompanies: []
  },
  mutations: {
    SET_COMPANIES(state, data) {
      state.companies = data;
    },
    SET_USER_COMPANY(state, data) {
      state.userCompanies = data;
    }
  },
  actions: {
    fetchCompanies({ commit }) {
      services(axios)
        .companies.getAll()
        .then(response => {
          commit('SET_COMPANIES', response.data.companies);
        });
    },
    fetchUserCompanies({ commit }) {
      services(axios)
        .companies.getUserCompanies()
        .then(response => {
          commit('SET_USER_COMPANY', response.data.companies);
        });
    }
  }
};
export default companies;
