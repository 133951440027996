<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h4>Descuentos</h4>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <th>Zona</th>
              <th>Cultivo</th>
              <th>Cantidad HA</th>
              <th>Valor HA</th>
              <th>Descuento</th>
            </thead>
            <tbody>
              <tr v-for="risk in risks" :key="risk.id">
                <td>
                  {{ risk.zone?.description }}
                  <span
                    class="tw-inline-block tw-items-center tw-uppercase tw-text-xs tw-font-bold tw-text-white tw-mx-1 tw-px-1.5 tw-rounded"
                    :class="[badgeClass(risk.company?.id)]"
                  >
                    {{ risk.company?.label }}
                  </span>
                </td>
                <td>
                  {{ risk.crop?.description }}
                </td>
                <td>{{ risk.numberOfHectares }} HA</td>
                <td>{{ risk.hectarePrice }} {{ risk.hectarePriceCurrencyId | unidadValorHA }}</td>
                <td>
                  <fg-input
                    cy-id="discount"
                    type="number"
                    class="discount-input"
                    min="0"
                    max="100"
                    addonRightIcon="fa fa-percent"
                    v-model="risk.discount"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { COMPANIES } from '@/utils/general';
export default {
  name: '',
  props: {
    risks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      companies: COMPANIES,
      formdata: {
        risks: []
      }
    };
  },
  computed: {
    isDataLoaded() {
      let idl = true;
      if (this.formdata.risks.length > 0) {
        this.formdata.risks.forEach(risk => {
          if (risk.zone === undefined || risk.crop === undefined) {
            idl = false;
          }
        });
      } else {
        idl = false;
      }
      return idl;
    }
  },
  methods: {
    badgeClass(item) {
      return this.companies.SAN_CRISTOBAL === item ? 'badge-san-cristobal ' : 'badge-sancor';
    }
  }
};
</script>

<style lang="scss" scoped>
.discount-input {
  width: 125px;
  margin: 0px;

  .input-group-append i.fa {
    width: 40px;
  }
}
.table td {
  white-space: nowrap;
}
</style>
