<template>
  <card class="card" v-bind="$attrs">
    <div>
      <form @submit.prevent>
        <fg-input
          cy-id="name"
          type="text"
          label="Nombre de la cobertura"
          placeholder="Nombre"
          v-model.trim="$v.formData.description.$model"
          :is-valid="!$v.formData.description.$error"
          :error-message="'Nombre de cobertura vació'"
        />

        <div v-if="showCompanies" class="form-group filtro">
          <label>Compañía</label>
          <select
            cy-id="crop-select"
            class="custom-select"
            :class="{ 'is-invalid': $v.formData.companyId.$error }"
            v-model="$v.formData.companyId.$model"
            :is-valid="!$v.formData.companyId.$error"
            :error-message="'Tipo de companía vacío'"
          >
            <!-- Should be replaced by a v-for with a fetch from the db -->
            <option value="">Seleccionar Compañía</option>
            <option v-for="(company, index) in userCompanies" :key="index" :value="company.id">
              {{ company.name }}
            </option>
          </select>
          <small v-if="$v.formData.companyId.$error" class="invalid-feedback">Seleccione alguna opción</small>
        </div>

        <fg-input
          v-if="isSancorCoverage"
          cy-id="sancor-id"
          type="text"
          label="Sancor ID de la cobertura"
          placeholder="Sancor ID"
          v-model.trim="$v.formData.sancorId.$model"
          :is-valid="!$v.formData.sancorId.$error"
          :error-message="'Sancor ID Invalido'"
        />

        <div class="form-group filtro">
          <label>Tipo de cobertura</label>
          <select
            cy-id="coverage-select"
            class="custom-select"
            :class="{ 'is-invalid': $v.formData.coverageType.$error }"
            v-model="$v.formData.coverageType.$model"
            :is-valid="!$v.formData.coverageType.$error"
            :error-message="'Tipo de cobertura vacía'"
          >
            <option v-for="(opt, i) in coverageOptions" :key="i" :value="opt.value">{{ opt.field }}</option>
          </select>
          <slot />
          <small v-if="$v.formData.coverageType.$error" class="invalid-feedback">Seleccione alguna opción</small>
        </div>

        <div class="form-group filtro">
          <label>Cultivo</label>
          <select
            cy-id="crop-select"
            class="custom-select"
            :class="{ 'is-invalid': $v.formData.cropDescription.$error }"
            v-model="$v.formData.cropDescription.$model"
            :is-valid="!$v.formData.cropDescription.$error"
            :error-message="'Tipo de cultivo vacío'"
          >
            <option value="">Seleccionar Cultivo</option>
            <option v-for="(crop, index) in cropsOptions" :key="index" :value="crop.description">
              {{ crop.description }}
            </option>
          </select>
          <small v-if="$v.formData.cropDescription.$error" class="invalid-feedback">Seleccione alguna opción</small>
        </div>

        <fg-input type="text" label="Link del video" placeholder="Link del video" v-model="formData.video" />

        <label>Descripción</label>
        <textarea
          cy-id="description"
          type="text"
          class="form-control"
          rows="4"
          placeholder="Descripción de la cobertura"
          v-model="formData.pdfDescription"
        />
        <div class="col-md-4">
          <div class="form-group">
            <label class="control-label">Permite multiples riesgos</label>
            <div>
              <p-switch v-model="formData.allowMultipleRisks" type="primary" />
            </div>
          </div>
        </div>

        <div class="flex-button-container mt-3">
          <p-button cy-id="save" type="primary" :loading="isSaving" @click.native.prevent="submitForm">
            Guardar
          </p-button>
        </div>
        <div class="clearfix" />
      </form>
    </div>
  </card>
</template>
<script>
import PSwitch from '@/components/Switch.vue';
import { coverageOptions } from '@/utils/coverages';
import { required, integer, requiredIf } from 'vuelidate/lib/validators';
import { scrollToTop } from '@/utils/general';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CoverageForm',
  components: {
    PSwitch
  },
  props: {
    isSaving: Boolean,
    module: {
      type: Object,
      default: () => ({
        id: '',
        description: '',
        coverageType: '',
        harvest: '',
        video: '',
        pdfDescription: '',
        cropDescription: '',
        sancorId: '',
        companyId: '',
        allowMultipleRisks: false
      })
    },
    editForm: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      crops: [],
      hasErrors: false,
      errorMessages: [],
      errorMessage: '',
      cropSelect: [],
      coverageOptions,
      formData: {
        id: null,
        description: '',
        coverageType: '',
        harvest: '',
        video: '',
        pdfDescription: '',
        cropDescription: '',
        sancorId: '',
        companyId: '',
        allowMultipleRisks: false
      }
    };
  },
  async mounted() {
    this.getCrops();
    await this.fetchUserCompanies();
    this.loadData();
  },

  validations: {
    formData: {
      description: {
        required
      },
      coverageType: {
        required
      },
      sancorId: {
        required: requiredIf(function (e) {
          return this.isSancorCoverage;
        }),
        integer
      },
      companyId: {
        required,
        integer
      },
      cropDescription: {
        required
      }
    }
  },
  computed: {
    ...mapState('companies', {
      userCompanies: state => state.userCompanies
    }),
    cropsOptions() {
      return this.crops.map(data => ({
        description: data.description,
        harvest: data.harvest,
        id: data.id
      }));
    },

    showCompanies() {
      return this.userCompanies.length > 1;
    },

    isSancorCoverage() {
      return parseInt(this.formData.companyId) === 1;
    }
  },
  methods: {
    ...mapActions('companies', ['fetchUserCompanies']),

    getCrops() {
      this.$api.crops.getAll().then(({ data }) => {
        this.crops = data.crops;
      });
    },
    loadData() {
      if (this.editForm) {
        this.formData.id = this.module.id;
        this.formData.description = this.module.description;
        this.formData.coverageType = this.module.coverageType;
        this.formData.harvest = this.module.harvest;
        this.formData.video = this.module.video;
        this.formData.pdfDescription = this.module.pdfDescription;
        this.formData.cropDescription = this.module.cropDescription;
        this.formData.sancorId = this.module.sancorId;
        this.formData.companyId = this.module.companyId;
        this.formData.allowMultipleRisks = this.module.allowMultipleRisks;
      } else if (!this.showCompanies) {
        this.formData.companyId = this.userCompanies[0].id;
      }
    },
    moduleHarvest() {
      this.cropSelect = this.crops.filter(c => {
        return c.description === this.formData.cropDescription;
      });
      if (this.cropSelect[0]) {
        this.formData.harvest = this.cropSelect[0]?.harvest;
      }
    },
    submitForm() {
      this.moduleHarvest();
      if (this.validate()) {
        this.$emit('submit', this.formData);
      } else {
        scrollToTop('.text-invalid');
      }
    },
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    }
  }
};
</script>
