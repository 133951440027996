<template>
  <div>
    <div class="row">
      <div class="col-xl-8">
        <user-form :user="user" :password="false" title="Editar usuario" @submit="saveUserChanges" />
      </div>
      <div class="col-xl-4">
        <logo-card :user="user" @addLogo="saveLogo" />
        <card title="Cambiar clave" sub-title="Complete este formulario para cambiar de clave.">
          <div>
            <key-form @passwordChange="passwordChange" />
          </div>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">
        <card title="Coberturas preferidas" sub-title="Listado de coberturas preferidas para realizar una cotización.">
          <div>
            <coverage-form
              v-if="this.isDataLoaded"
              :selected-coverages="this.user.coverages"
              @select="select"
              @deselect="deselect"
            />
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import UserForm from '@/components/Users/Form/UserForm';
import KeyForm from '@/components/Usuario/Form/KeyForm';
import CoverageForm from '@/components/Usuario/Form/CoverageForm';
import LogoCard from '@/components/Cards/LogoCard';

export default {
  name: 'EditarPerfil',
  components: {
    UserForm,
    KeyForm,
    CoverageForm,
    LogoCard
  },
  data() {
    return {
      userchange: {},
      user: {},
      isDataLoaded: false
    };
  },
  computed: {
    userId() {
      return this.$auth.user().id;
    }
  },
  mounted() {
    this.fetchUserData();
  },
  methods: {
    fetchUserData() {
      this.$api.users.getOne(this.userId).then(async ({ data }) => {
        this.user = data;
        this.userchange = data;
        try {
          const coveragesResponse = await this.fetchUserCoverages();
          this.user.coverages = coveragesResponse.data.modules.map(coverage => coverage.id);
        } catch (error) {
          console.log(error);
        }

        this.isDataLoaded = true;
      });
    },

    saveLogo(formData) {
      this.$api.users.setLogo(this.userId, formData).then(() => {
        this.fetchUserData(this.userId);
        this.$swal({
          title: 'Listo!',
          text: 'El logo fue registrado.',
          type: 'success'
        });
        this.user.logo = formData.logo;
      });
    },
    saveUserChanges() {
      this.$api.users
        .update(this.user.id, this.user)
        .then(() => {
          this.$swal({
            title: 'Listo!',
            text: 'Los cambios fueron registrados.',
            type: 'success'
          });
          this.$router.push({ name: 'perfil' });
        })
        .catch(error => {
          this.handleErrors(error);
        });
    },
    handleErrors(error) {
      this.$swal({
        title: 'Error!',
        text: 'Ocurrio un error mientras se realizaba la operacion.',
        type: 'error',
        footer: 'ERROR ' + error
      });
    },
    passwordChange(user) {
      this.userchange.password = user.password;
      this.userchange.password_confirmation = user.password_confirmation;
      this.userchange.current_password = user.current_password;
      this.$api.users
        .update(this.user.id, this.userchange)
        .then(response => {
          this.$swal({
            title: 'Listo!',
            text: 'La clave fue cambiada exitosamente.',
            type: 'success'
          });
          this.$router.push({ name: 'perfil' });
        })
        .catch(error => {
          this.handleErrors(error);
        });
    },
    fetchUserCoverages() {
      return this.$api.users.getCoverages(this.userId);
    },
    select(module) {
      this.$api.users.addCoverage(this.userId, module);
    },
    deselect(module) {
      this.$api.users.deleteCoverage(this.userId, module);
    }
  }
};
</script>
